import { fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: 'S/N',
        type: tableau.dataTypeEnum.int
    },
    {
        name: 'Station',
        type: tableau.dataTypeEnum.string
    },
    {
        name: 'Inlet Temperature',
        type: tableau.dataTypeEnum.int
    },
    {
        name: 'Outlet Temperature',
        type: tableau.dataTypeEnum.string
    },
    {
        name: 'Inlet Pressure',
        type: tableau.dataTypeEnum.int
    },
    {
        name: 'Outlet Pressure',
        type: tableau.dataTypeEnum.int
    },
    {
        name: 'Flow Rate',
        type: tableau.dataTypeEnum.int
    },
    {
        name: 'Total Flow',
        type: tableau.dataTypeEnum.string
    },
    {
        name: 'Datetime',
        type: tableau.dataTypeEnum.string
    },
]

const getReport = async () => {
    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var account = (connectionData_1.account || "");
    var geo = (connectionData_1.geo || "uae") as Geo;
    let url = `/logistics/v1/report/prms?report_type=json`

    let body = {
        "accountId": account,
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
        "endDate": new Date().toISOString(),
        "station": "EMLPLC_Injestion"
    }

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };

    let res = await fetchBase(url, options, tableau.password, geo);
    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const prms_report: Report = {
    id: 'prmsReport7Days',
    alias: 'PRMS Report',
    columns,
    get: getReport,
};