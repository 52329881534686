import {backend, fetchBase, Geo} from '../lib/backend';
import {ColumnDef, Report} from '../lib/types';
import {mapObjectOntoTableauStruct} from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: "equipment_id",
        type: tableau.dataTypeEnum.string,
        alias: "Equipment ID",
        description: "Machine ID"
    },
    {
        name: "equipment",
        type: tableau.dataTypeEnum.string,
        alias: "Equipment",
        description: "Machine model and number plate"
    },
    {
        name: "project",
        type: tableau.dataTypeEnum.string,
        alias: "Project",
        description: "Machine Current Project"
    },

    {
        name: "last_ping",
        type: tableau.dataTypeEnum.datetime,
        alias: "Date",
        description: "Last ping date"
    }
];

const getReport = async () => {

    const connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    const geo = (connectionData_1.geo || "uae") as Geo;
    const account = (connectionData_1.account || "");

    const url = `/app/reports/sync?report_type=json`;

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);

    let body = {
        "equipmentIds": equipments.map(a => a.id),
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
        "endDate": new Date().toString(),
        "reportType": "Unresponsive",
        "formatType": "json",
        "projectId": "",
        "summary": false
    }
    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };

    let res = await fetchBase(url, options, tableau.password, geo);
    return mapObjectOntoTableauStruct(res, columns);
}

export const unresponsive_report: Report = {
    id: 'UnresponsiveReport7Days',
    alias: 'Unresponsive Device Report (7 Days)',
    columns,
    get: getReport,

};
