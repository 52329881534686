import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "distanceOutside",
    type: tableau.dataTypeEnum.float,
    alias: "Distance Outside"
  },
  {
    name: "project_id",
    type: tableau.dataTypeEnum.string,
    alias: "Project Id",
    description: "Project Id"
  },
  {
    name: "device_id",
    type: tableau.dataTypeEnum.string,
    alias: "Device Id",
    description: "Device Id"
  },
  {
    name: "date_raw",
    type: tableau.dataTypeEnum.float,
    alias: "Date raw",
  },
  {
    name: "telematicsHoursInside",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics hours inside"
  },
  {
    name: "telematicsHoursIgnitionIgnoreInside",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics hours ignore inside"
  },
  {
    name: "distance",
    type: tableau.dataTypeEnum.float,
    alias: "Distance Traveled"
  }, {
    name: "distanceInside",
    type: tableau.dataTypeEnum.float,
    alias: "Distance Inside"
  },
  {
    name: "equipment_name",
    type: tableau.dataTypeEnum.string,
    alias: "Equipment name"
  },
  {
    name: "telematicsHoursIgnitionIgnoreOutside",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics hours ignition outside"
  },
  {
    name: "telematicsHours",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics hours"
  },
  {
    name: "telematicsHoursOutside",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics hours outside"
  },
  {
    name: "totalEngineHours",
    type: tableau.dataTypeEnum.float,
    alias: "Total engine hours"
  },
  {
    name: "date",
    type: tableau.dataTypeEnum.date,
    alias: "Date"
  },
];

const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `/app/reports/sync?report_type=json`;

  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo);
  let groups = await backend.getGroupsUnderAccount(
    account,
    tableau.password,
    geo
  )
  let promises = groups.map((group) => {

    let body = {
      "equipmentIds": equipments.map(a => a.id),
      "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
      "endDate": new Date().toISOString(),
      "reportType": "Geofence",
      "formatType": "json",
      "groupId": group.id,
    }
    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body)
    };

    return fetchBase(url, options, tableau.password, geo);

  });
  let data = await Promise.all(promises);

  let result = data.reduce((acc, val) => acc.concat(val), []);
  const tableData = mapObjectOntoTableauStruct(result, columns);
  return tableData;
}

export const geofence_report: Report = {
  id: 'Geofence',
  alias: 'Geofence Report',
  columns,
  get: getReport,

};