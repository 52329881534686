import { Equipment } from "@tenderd/schema";
export enum Geo {
  UAE = "uae",
  SA = "sa",
  Test = "test",
  NG = "ng",
  AUH = "auh",
  NL = "nl",
}
export const ENDPOINT = "https://api.tenderd.com";

export interface Reference {
  label: string;
  id: string;
}
export interface Document<T> {
  id: string;
  doc: T;
}

export interface GetEquipmentByAccountForm {
  accountId: string;
  limit: number;
  lastVisible?: Date;
}

export interface GetEquipmentByGroupForm {
  groupId: string;
  limit: number;
  lastVisible?: Date;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface LoginResponse {
  user: {
    uid: string;
    email: string;
  };
  refreshToken: string;
  token: string;
}

export interface ForgetPasswordForm {
  email: string;
}

export interface StatusForm {
  envelopeId: string;
}

export const fetchWithoutToken = (url: string, params: RequestInit) => {
  params.headers = {
    ...(params.headers || {}),
    "Content-Type": "application/json",
  };
  return fetch(`${ENDPOINT}${url}`, params).then((res) => {
    return res.json().then((body) => {
      if (res.status >= 400 && res.status < 600) {
        throw new Error(
          (body && body.error) || "Something went wrong: " + res.status
        );
      }
      return body;
    });
  });
};

export const fetchBase = async (
  url: string,
  params: RequestInit,
  token: string,
  geo: Geo
) => {
  params.headers = {
    ...(params.headers || {}),
    Authorization: "Bearer " + token,
    geo: geo,
  };
  let response = await fetch(`${ENDPOINT}${url}`, params);
  if (response.status === 401) {
    const connectionData = JSON.parse(tableau.connectionData);
    const result = await backend.refreshTokens(connectionData.refreshToken);
    tableau.password = result.token;
    tableau.connectionData = JSON.stringify({
      ...connectionData,
      refreshToken: result.refreshToken,
      geo: geo,
    });

    params.headers = {
      ...(params.headers || {}),
      Authorization: "Bearer " + result.token,
      geo: geo,
    };
    response = await fetch(`${ENDPOINT}${url}`, params);
    return response.json();
  }
  return response.json();
};

export const backend = {
  login: (form: LoginForm) => {
    return fetchWithoutToken(`/app/auth/login`, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(form),
    }) as Promise<LoginResponse>;
  },

  forgetPassword: (form: ForgetPasswordForm) => {
    return fetchWithoutToken(`/app/auth/forget-password`, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify(form),
    }) as Promise<StatusForm>;
  },

  refreshTokens: (refreshToken: string) => {
    return fetchWithoutToken(`/app/auth/refresh-tokens`, {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ refreshToken }),
    }) as Promise<LoginResponse>;
  },

  getAccountByUserEmail: (email: string, token: string, geo: Geo) => {
    return fetchBase(
      `/app/accounts?email=${email}`,
      {
        mode: "cors",
        method: "GET",
      },
      token,
      geo
    ) as Promise<Document<any>[]>;
  },

  getEquipmentsByAccount: (
    form: GetEquipmentByAccountForm,
    token: string,
    geo: Geo
  ) => {
    return fetchBase(
      form.lastVisible
        ? `/app/equipments?accountId=${form.accountId}&limit=${form.limit}&lastVisible=${form.lastVisible}`
        : `/app/equipments?accountId=${form.accountId}&limit=${form.limit}`,
      {
        mode: "cors",
        method: "GET",
      },
      token,
      geo
    ) as Promise<Document<Equipment>[]>;
  },
  getEquipmentsByGroup: (
    form: GetEquipmentByGroupForm,
    token: string,
    geo: Geo
  ) => {
    return fetchBase(
      form.lastVisible
        ? `/app/equipments?groupId=${form.groupId}&limit=${form.limit}&lastVisible=${form.lastVisible}`
        : `/app/equipments?groupId=${form.groupId}&limit=${form.limit}`,
      {
        mode: "cors",
        method: "GET",
      },
      token,
      geo
    ) as Promise<Document<Equipment>[]>;
  },
  getGroupsUnderAccount(
    accountId: string,
    token: string,
    geo: Geo
  ): Promise<Document<any>[]> {
    return fetchBase(
      `/app/groups/projects?accountId=${accountId}`,
      {
        mode: "cors",
        method: "GET",
      },
      token,
      geo
    );
  },
};
