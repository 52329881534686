// index.ts
import {
  ColumnDef,
  Value,
  ServiceResponse,
  Tabular,
  SchemaColumn,
  Schema,
  Report,
} from './types'
import moment from 'moment'
//import tableau from './tableau.d';

// Read a page's GET URL variables and return them as an associative array.
export const getParameterByName = (
  name: string,
  url?: string,
): string | null => {
  if (!url) url = window.location.href
  name = name.replace(/[[]]/g, '\\$&') //name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

// tableau needs "well-formatted" object keys. This function helps turn any key/string into a tableau-ok object key
export const strSanitize = (s: string, i?: number | string): string => {
  if (s !== ' ' && s !== '') {
    return s.replace(/[^\w\d_]/g, '_')
    //.replace(/ /g, '_').replace('/test', '_').replace('?', '_').replace('\'', '_').replace('(', '_').replace(')', '_');
  }

  return 'undefined_' + i
}

// map the output of the array strcuture onto a tableau compatible structure
// param resp: response of the ajax call (which is an array of arrays)
// pararm colLabel: the headers pre defined (note that this is also the first row of the rerponse)
export const mapArrayOntoTableauStruct = (
  [_, content]: ServiceResponse,
  colLabel: ColumnDef[],
): Tabular => {
  // init output table
  const tableData: Tabular = []
  // Iterate over the array
  content.forEach((row) => {
    const c: { [k: string]: Value } = {}

    colLabel.forEach((col: ColumnDef, j: number) => {
      const v = row[j]
      c[strSanitize(col.name, j)] = v
      return true
    })

    tableData.push((c as unknown) as Value[]) // casting here otherwise does not work with TS definitions
    return true
  })

  return tableData
}

export const mapObjectOntoTableauStruct = (
  content: any[],
  colLabel: ColumnDef[],
): any => {
  // init output table
  const tableData: Tabular = []
  // Iterate over the array
  content.forEach((row) => {
    const c: { [k: string]: Value } = {}
    colLabel.forEach((col: ColumnDef, j: number) => {
      const v = row[col.name]
      if (col.name === 'date') {
        let tempDate = new Date(
          v?.split('-')[2],
          Number(v?.split('-')[1]) - 1,
          v?.split('-')[0],
        )
        //TODO moment js to format this date as utc
        let finalDate: any = new Date(
          tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000,
        )
        finalDate = moment.utc(finalDate).format()
        // @ts-ignore
        c[strSanitize(col.name, j)] = finalDate
      } else {
        c[strSanitize(col.name, j)] = v
      }
      return true
    })
    tableData.push((c as unknown) as Value[]) // casting here otherwise does not work with TS definitions
    return true
  })

  return tableData
}

export const tableauColumnsFromArrayHeaders = (
  colLabel: ColumnDef[],
): SchemaColumn[] =>
  colLabel.map((c: ColumnDef, i: number) => {
    return {
      id: strSanitize(c.name, i),
      alias: c.alias || c.name,
      dataType: c.type || tableau.dataTypeEnum.string,
      description: c.description || '-',
    }
  })

export const schemaFromReport = (report: Report): Schema => ({
  id: report.id,
  alias: report.alias,
  columns: tableauColumnsFromArrayHeaders(report.columns),
})

export const formatCsv = async (resp: any, columns: ColumnDef[]) => {
  let res = await resp.text()
  var data = res.replace(/\"/g, '').replace(/\(/g, '').replace(/\)/g, '')
  //Convert csv to json data
  var csv = data
  var lines = csv.split('\n')
  var result = []
  var headers = lines[0].split(' ').join('_').split(',')
  for (var i = 1; i < lines.length; i++) {
    var currentline: any = lines[i].split(',')
    result.push(currentline)
  }
  const tabular: ServiceResponse = [headers, result] as ServiceResponse
  const tableData = mapArrayOntoTableauStruct(tabular, columns)
  return tableData
}
