import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';
import { backend, Geo } from "../lib/backend";

const columns: ColumnDef[] = [
    {
        name: 'slno',
        type: tableau.dataTypeEnum.int,
        alias: "SL #"
    },
    {
        name: 'number_plate',
        type: tableau.dataTypeEnum.string,
        alias: "PLATE NO."
    },
    {
        name: 'code_number',
        type: tableau.dataTypeEnum.string,
        alias: 'CODE NO.'
    }, {
        name: 'type_of_vehicle',
        type: tableau.dataTypeEnum.string,
        alias: 'TYPE OF VEHICLE'
    },
    {
        name: 'id_number',
        type: tableau.dataTypeEnum.string,
        alias: 'ID NUMBER'
    }, {
        name: 'da_name',
        type: tableau.dataTypeEnum.string,
        alias: 'DA NAME'
    }, {
        name: 'residence_area',
        type: tableau.dataTypeEnum.string,
        alias: 'RESIDENCE AREA'
    }, {
        name: 'vehicle_source',
        type: tableau.dataTypeEnum.string,
        alias: 'VEHICLE SOURCE'
    }, {
        name: 'station',
        type: tableau.dataTypeEnum.string,
        alias: 'STATION'
    }, {
        name: 'mile',
        type: tableau.dataTypeEnum.string,
        alias: 'MILE'
    }, {
        name: 'date_string',
        type: tableau.dataTypeEnum.date,
        alias: 'DATE'
    }, {
        name: 'ignition_on',
        type: tableau.dataTypeEnum.string,
        alias: 'TIME IN(IGNITION ON)'
    }, {
        name: 'start_odometer',
        type: tableau.dataTypeEnum.int,
        alias: 'STARTING KM(ODO)'
    }, {
        name: 'previous_service_km',
        type: tableau.dataTypeEnum.int,
        alias: 'PREVIOUS SERVICE KM'
    }, {
        name: 'next_service_km',
        type: tableau.dataTypeEnum.int,
        alias: 'NEXT SERVICE KM'
    }, {
        name: 'ignition_off',
        type: tableau.dataTypeEnum.string,
        alias: 'TIME OUT(IGNITION OFF)'
    }, {
        name: 'end_odometer',
        type: tableau.dataTypeEnum.int,
        alias: 'ENDING KM(ODO)'
    }, {
        name: 'total_km',
        type: tableau.dataTypeEnum.int,
        alias: 'TOTAL KM/DAY'
    }, {
        name: 'off_duty_km',
        type: tableau.dataTypeEnum.int,
        alias: 'OFF DUTY KM'
    }, {
        name: 'day_off_1',
        type: tableau.dataTypeEnum.string,
        alias: 'DAY OFF 1'
    }, {
        name: 'day_off_2',
        type: tableau.dataTypeEnum.string,
        alias: 'DAY OFF 2'
    }
];

const getReport = async () => {

    const connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    const geo = (connectionData_1.geo || "uae") as Geo;

    const url = `https://api.tenderd.com/app/reports/sync?report_type=json`;

    let equipments = await backend.getEquipmentsByGroup({
        groupId: 'PocNQTOUJwBjoMkBdpHl',
        limit: 500,
    }, tableau.password, geo)

    let body = {
        "equipmentIds": equipments.map(a => a.id),
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
        "endDate": new Date().toString(),
        "reportType": "DistanceKMUpdateReport",
        "groupId": 'PocNQTOUJwBjoMkBdpHl'
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${tableau.password}`);
    myHeaders.append("geo", geo);
    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };
    let data = await fetch(url, options).then(resp => resp.json())
    const tableData = mapObjectOntoTableauStruct(data, columns);
    return tableData;
}

export const distance_km_update_report: Report = {
    id: 'DistanceKMUpdateReport',
    alias: 'Distance KM Update',
    columns,
    get: getReport
};
