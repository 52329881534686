import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: "id",
        type: tableau.dataTypeEnum.string,
        alias: "Eqiupment ID",
        description: "Equipment ID",
    },
    {
        name: "date",
        type: tableau.dataTypeEnum.date,
        alias: "Date",
        description: "Date"
    },
    {
        name: "move_time",
        type: tableau.dataTypeEnum.float,
        alias: "Move Time",
        description: "Move Time"
    },
    {
        name: "on_time",
        type: tableau.dataTypeEnum.float,
        alias: "On Time",
        description: "On Time"
    },
    {
        name: "distance",
        type: tableau.dataTypeEnum.float,
        alias: "Distance",
        description: "Distance"
    },
    {
        name: "fuel_data",
        type: tableau.dataTypeEnum.float,
        alias: "Fuel Consumed",
        description: "Fuel Consumed"
    },
];
const getReport = async () => {

    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var geo = (connectionData_1.geo || "uae") as Geo;
    var account = (connectionData_1.account || "");

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "GET",
        headers: myHeaders
    };

    let date1 = new Date()
    // get date 7 days ago
    let date2 = new Date()
    date2.setDate(date2.getDate() - 30)

    let promsises = equipments.map(async (equipment) => {
        return fetchBase(`/app/timesheets/telematics?equipmentId=${equipment.id}&startDate=${date2.toISOString()}&endDate=${date1.toISOString()}`, options, tableau.password, geo).then((data) => {
            return data.map((a: any) => ({
                id: equipment.id,
                ...a
            }))
        });
    });

    let timesheets_raw = await Promise.all(promsises);
    let timesheets = timesheets_raw.flat();

    let res = timesheets.map((a) => {
        console.log('date', a);

        return {
            id: a.doc?.id,
            date: a.doc?.date,
            move_time: a.doc?.move_time,
            on_time: a.doc?.on_time,
            distance: a.doc?.distance / 1000,
            fuel_data: a.doc?.fuel_data?.consumed,
        }

    });

    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const timesheet_telematics_report: Report = {
    id: 'TimesheetTelematicsReport',
    alias: 'Timesheet Telematics Report',
    columns,
    get: getReport,
};