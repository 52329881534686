import { MeterLookup } from "@tenderd/schema";
import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: "id",
        type: tableau.dataTypeEnum.string,
        alias: "Eqiupment ID",
        description: "Equipment ID",
    },
    {
        name: "model",
        type: tableau.dataTypeEnum.string,
        alias: "Equipment Model",
        description: "Equipment Name"
    },
    {
        name: "type",
        type: tableau.dataTypeEnum.string,
        alias: "Equipment Type",
    },
    {
        name: "number_plate",
        type: tableau.dataTypeEnum.string,
        alias: "Number plate",
        description: "Total fuel consumed"
    },
    {
        name: "telematics",
        type: tableau.dataTypeEnum.bool,
        alias: "Telematics",
        description: "Is telematics installed"
    },
    {
        name: "heading",
        type: tableau.dataTypeEnum.int,
        alias: "Heading",

    },
    {
        name: "speed",
        type: tableau.dataTypeEnum.int,
        alias: "Speed",
    },
    {
        name: "fuel_level",
        type: tableau.dataTypeEnum.int,
        alias: "Fuel Level",
    },
    {
        name: "ignition",
        type: tableau.dataTypeEnum.bool,
        alias: "Ignition",
    },
    {
        name: "odometer",
        type: tableau.dataTypeEnum.int,
        alias: "Odometer",
    },
    {
        name: "hourmeter",
        type: tableau.dataTypeEnum.int,
        alias: "Hourmeter",
    },
    {
        name: "latitude",
        type: tableau.dataTypeEnum.float,
        alias: "Latitude",
    },
    {
        name: "longitude",
        type: tableau.dataTypeEnum.float,
        alias: "Longitude",
    },
    {
        name: "last_updated",
        type: tableau.dataTypeEnum.datetime,
        alias: "Last Updated",
        description: "Timestamp of latest ping from telematics device"
    }
];



const getReport = async () => {

    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var geo = (connectionData_1.geo || "uae") as Geo;
    var account = (connectionData_1.account || "");

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "GET",
        headers: myHeaders
    };

    let promsises = equipments.map(async (equipment) => {
        return fetchBase("/telematics/devices/" + equipment.id, options, tableau.password, geo);
    });

    let devices = await Promise.all(promsises);
    console.log(devices.map(a => a.hourmeter));

    let res = equipments.map((a) => {
        //@ts-ignore
        const hourmeterEnabled = !MeterLookup[a.doc.type] || MeterLookup[a.doc.type].hourmeter;
        //@ts-ignore
        const odometerEnabled = !MeterLookup[a.doc.type] || MeterLookup[a.doc.type].odometer;
        console.log('test1', odometerEnabled, typeof odometerEnabled);

        let device = devices.find(d => d._id === a.id);
        return {
            id: a.id,
            model: a.doc.model,
            number_plate: a.doc.number_plate.raw,
            type: a.doc.type,
            telematics: device ? true : false,
            heading: device?.direction,
            speed: device?.speed,
            fuel_level: device?.fuel_level || undefined,
            ignition: device?.ignition_status,
            odometer: odometerEnabled && device && device.odometer ? Math.round(device?.odometer) : '-',
            hourmeter: hourmeterEnabled && device && device.hourmeter ? Math.round(device?.hourmeter) : '-',
            latitude: device?.location?.coordinates[0],
            longitude: device?.location?.coordinates[1],
            last_updated: device?.last_telematics_time

        }
    });

    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const equipment_report: Report = {
    id: 'EquipmentReport',
    alias: 'Equipment Report',
    columns,
    get: getReport,
};