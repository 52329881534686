import { Geo, backend, fetchBase } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';


const columns: ColumnDef[] = [
    {
        name: 'S/N',
        type: tableau.dataTypeEnum.int,
    },
    {
        name: 'DRIVER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'DISPATCH DATE/TIME',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'SKID OUT',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'SKID OWNER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'D/S ARRIVAL DATE/TIME',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'SKID CONNECTED TO PRMS (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'TRUCK NUMBER',
        type: tableau.dataTypeEnum.int,
    },
    {
        name: 'CONTRACTOR',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'OWNER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'DESTINATION',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'CONTRACT OWNER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'WAITING TIME',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'D/S DEP DATE/TIME',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'PG RETURN DATE/TIME',
        type: tableau.dataTypeEnum.int,
    },
    {
        name: 'RETURN SKID',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'SKID DISCONNECTION FROM PRMS (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'METER READING BEFORE',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'METER READING AFTER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'TOTAL METER km',
        type: tableau.dataTypeEnum.int,
    },
    {
        name: 'TOTAL TRANSIT TIME',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'TURN AROUND TIME',
        type: tableau.dataTypeEnum.string,
    },
]

const getReport = async () => {
    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var account = (connectionData_1.account || "");
    var geo = (connectionData_1.geo || "uae") as Geo;
    let url = `/logistics/v1/report/odometer?report_type=json`

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);
    let body = {
        "accountId": account,
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
        "endDate": new Date().toISOString(),
        "equipments": equipments.map(a => a.id),
    }

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };

    let res = await fetchBase(url, options, tableau.password, geo);
    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const odometer_report: Report = {
    id: 'odometerReport7Days',
    alias: 'Odometer Report',
    columns,
    get: getReport,
};