import React, { useState, useEffect } from 'react';

import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Row, List, Spin, Tag } from 'antd';
import 'antd/dist/antd.css';
import './TableauWebConnectPage.css';

import useTableau from './lib/useTableau';
import { backend, Geo, LoginResponse, Reference } from './lib/backend';


const { Password } = Input;


let geos: any = {
    [Geo.UAE]: {
        label: "United Arab Emirates",
        color: "green"
    },
    [Geo.SA]: {
        label: "Saudi Arabia",
        color: "blue"
    },
    [Geo.NL]: {
        label: "Netherlands",
        color: "red"
    },
    [Geo.NG]: {
        label: "Nigeria",
        color: "orange"
    },
    [Geo.AUH]: {
        label: "Abu Dhabi",
        color: "purple"
    }
}

const TableauWebConnectPage: React.FunctionComponent<{}> = () => {
    const tableauInstance = useTableau();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [spin, setSpin] = useState(false);
    const [state, setState] = useState(1);
    const [loginResponse, setLoginResponse] = useState<LoginResponse>();

    const [geoLookup, setGeoLookup] = useState<{ [accountId: string]: Geo }>({});
    const [accounts, setAccounts] = useState<Reference[]>([]);


    const checkValidity = () => {
        return email && password && email !== "" && password !== "";
    };

    const query = new URLSearchParams(window.location.search);
    const urlToken = query.get('token');
    const urlGeo = query.get('geo');
    const urlEmail = query.get('email');
    const urlAccount = query.get('account');

    useEffect(() => {
        if (urlToken && urlGeo && urlEmail) {
            setSpin(true);
            backend.refreshTokens(urlToken).then((result) => {
                tableau.password = result.token;
                tableau.connectionData = JSON.stringify({ refreshToken: result.refreshToken, geo: urlGeo, account: urlAccount });
                tableau.username = urlEmail.trim();
                tableau.submit(); // This sends the connector object to Tableau
            });
        }
    }, [urlToken, urlGeo, urlEmail, urlAccount]);

    const selectAccount = (accountId: string, geo: Geo) => {
        if (loginResponse) {
            tableau.password = loginResponse.token;
            tableau.connectionData = JSON.stringify({ refreshToken: loginResponse.refreshToken, geo: geo, account: accountId });
            tableau.username = email.trim();
            tableau.submit(); // This sends the connector object to Tableau
        }
    }

    const login = async () => {
        if (!checkValidity()) {
            message.error('Error logging in. Try again later.');
            return;
        }
        setLoading(true);

        backend.login({
            email: email.trim(),
            password: password,
        })
            .then((result) => {

                setLoginResponse(result);
                setState(2);

                let newAccounts: Reference[] = [];
                let newLookup: { [accountId: string]: Geo } = {};
                let promises = [Geo.UAE, Geo.SA, Geo.NG, Geo.NL].map((geo) => {
                    // @ts-ignore
                    return backend.getAccountByUserEmail(email.trim(), result.token, geo).then(geoAccounts => {
                        geoAccounts.forEach(account => {
                            if (!newLookup[account.id]) {
                                newLookup[account.id] = geo;
                                newAccounts.push({
                                    id: account.id,
                                    label: account.doc.name
                                });
                            }
                        });
                        return true;
                    }).catch(() => {
                        return true;
                    });
                });
                Promise.all(promises).then(() => {
                    console.log("Lookup: ", newLookup);
                    setLoading(false);
                    setGeoLookup(newLookup);
                    setAccounts(newAccounts);
                    if (newAccounts.length === 1) {
                        //selectAccount(newAccounts[0].id, newLookup[newAccounts[0].id]);
                    }
                });
            })
            .catch((error) => {
                setLoading(false);
                message.error("Something went wrong when trying to login");
            });
    };

    const forgotPassword = () => {
        if (!email) {
            message.error('Please enter the email address');
            return;
        }
        setLoading(true);

        backend
            .forgetPassword({
                email,
            })
            .then(() => {
                setLoading(false);
                message.success(
                    'Password  reset successfully. Check your email inbox.'
                );
            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message);
            });
    };

    useEffect(() => {
        localStorage.removeItem('geoOverride');
        window.scrollTo(0, 0);
        if (window.location.search) {
            let arr = window.location.search.replace('?', '').split('&');
            let params: any = {};
            arr.forEach((val) => {
                let a = val.split('=');
                params[a[0]] = a[1].replace(/%20/g, ' ');
                return true;
            });
        }
    }, []);

    return (
        <div
            className='tableau-web-connect-page'
        >
            <Spin tip="Automatically logging you in..." spinning={spin}>
                <Card className="holder">

                    <Row style={{ justifyContent: "center", alignItems: "center", marginBottom: 20, marginTop: 20 }}>
                        <div
                            style={{
                                height: 64,
                                width: 64,
                                borderRadius: 8,
                                overflow: "hidden",
                                border: "1px solid #eee",
                            }}>
                            <img alt="tenderd" src="https://storage.googleapis.com/tenderd-ui/assets/logo-icon.png" height="100%" />
                        </div>
                        <img
                            alt="connection"
                            src="https://i.stack.imgur.com/twIm6.png"
                            style={{ opacity: 0.1, width: 64, height: 64, marginLeft: 10, marginRight: 10 }}
                        />
                        <div
                            className="install-modal-icon"
                            style={{
                                height: 64,
                                width: 64,
                                borderRadius: 8,
                                overflow: "hidden",
                                border: "1px solid #eee",
                                padding: 5
                            }}>
                            <img src="https://zappysys.com/blog/wp-content/uploads/2018/06/tableau-integration-logo.png" height="100%" width="100%" alt="tableau logo" />
                        </div>
                    </Row>

                    <h2>Connect to Tableau</h2>

                    <p
                        className="semibold"
                        style={{ marginBottom: 40, color: '#888', textAlign: "center" }}
                    >
                        Login with your Tenderd Track credentials to visualise data from Tenderd on Tableau
                    </p>

                    {state === 1 &&
                        <Form layout="vertical">

                            <Form.Item
                                label={
                                    <p
                                        className="semibold"
                                        style={{ color: '#666' }}
                                    >
                                        Email address
                                    </p>
                                }
                            >
                                <Input
                                    className="item"
                                    placeholder="Email address.."
                                    size="large"
                                    prefix={
                                        <UserOutlined
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    }
                                    name="email"
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(e.target.value.trim())
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <div
                                        className="semibold"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: 400,
                                            color: '#666',
                                        }}
                                    >
                                        <div style={{ flexGrow: 1 }}>
                                            Password
                                        </div>

                                        <Button
                                            onClick={() => forgotPassword()}
                                            type="link"
                                            style={{
                                                marginRight: 0,
                                                paddingRight: 0,
                                            }}
                                        >
                                            Forgot password?
                                        </Button>
                                    </div>
                                }
                            >
                                <Password
                                    className="item"
                                    placeholder="Password.."
                                    size="large"
                                    prefix={
                                        <KeyOutlined
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    }
                                    name="cc-num"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    block
                                    size="large"
                                    loading={loading}
                                    className="next-butt"
                                    htmlType="submit"
                                    onClick={() => login()}
                                >
                                    Login
                                </Button>
                            </Form.Item>

                            <div className="inner">
                                <div>
                                    <div className="para semibold">
                                        <p>Don't have an account yet?</p>
                                        <a
                                            href={'https://track.tenderd.com/create-account'}
                                        >
                                            Signup here
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }

                    {state === 2 &&
                        <div>
                            <h3>Select account</h3>
                            <List
                                loading={loading ? { tip: "Loading accounts..." } : undefined}
                                dataSource={accounts}
                                bordered
                                style={{ cursor: "pointer" }}
                                renderItem={account => (
                                    <List.Item onClick={() => selectAccount(account.id, geoLookup[account.id])} key={account.id} actions={[
                                        <Tag color={geos[geoLookup[account.id]].color}>
                                            {geos[geoLookup[account.id]].label}
                                        </Tag>
                                    ]}>
                                        {account.label}
                                    </List.Item>
                                )} />
                        </div>
                    }
                </Card>

            </Spin>
        </div>
    );
};

export default TableauWebConnectPage;
