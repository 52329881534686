import { fetchBase, Geo } from "../lib/backend";
import { ColumnDef, Report } from "../lib/types";
import { mapObjectOntoTableauStruct } from "../lib/utils";

const columns: ColumnDef[] = [
	{
		name: "Vehicle id",
		type: tableau.dataTypeEnum.string,
		alias: "Machine ID"
	},
	{
		name: "Vehicle",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Number Plate",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Trip name",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Violation Type",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Started at",
		type: tableau.dataTypeEnum.datetime,
	},
	{
		name: "Ended at",
		type: tableau.dataTypeEnum.datetime,
	},
	{
		name: "Start location",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "End location",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Duration (hours)",
		type: tableau.dataTypeEnum.float,
	},
	{
		name: "Distance (km)",
		type: tableau.dataTypeEnum.string,
	},
	{
		name: "Top speed (kmph)",
		type: tableau.dataTypeEnum.string,
	},
];


const getReport = async () => {

	var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
	var geo = (connectionData_1.geo || "uae") as Geo;
	var account = (connectionData_1.account || "");
	const url = `/logistics/v1/report/violations?report_type=json`;

	let body = {
		accountId: account,
		startDate: new Date(
			new Date().getTime() - 1000 * 60 * 60 * 24 * 7
		).toString(),
		endDate: new Date().toString(),
		geo: geo
	};

    var myHeaders = { "Content-Type": "application/json" };

	const options: RequestInit = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify(body)
	};

	let res = await fetchBase(url, options, tableau.password, geo);
	const tableData = mapObjectOntoTableauStruct(res, columns);
	return tableData;
}

export const trips_violations_report: Report = {
	id: "tripsViolationsReport7Days",
	alias: "Trips Violations Report (7 Days)",
	columns,
	get: getReport
};
