import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "machine_id",
    type: tableau.dataTypeEnum.string,
    alias: "Machine ID",
    description: "Machine ID",
  },
  {
    name: "machine_model",
    type: tableau.dataTypeEnum.string,
    alias: "Machine Model",
    description: "Machine Model"
  },
  {
    name: "machine_plate_number",
    type: tableau.dataTypeEnum.string,
    alias: "Machine plate number",
    description: "Machine plate number"
  },
  {
    name: "date",
    type: tableau.dataTypeEnum.date,
    alias: "Date",
    description: "Date"
  },
  {
    name: "fuel_consumed",
    type: tableau.dataTypeEnum.float,
    alias: "Fuel Consumed",
    description: "Fuel Consumed"
  }, {
    name: "engine_on",
    type: tableau.dataTypeEnum.float,
    alias: "Engine On",
    description: "Engine On"
  }, {
    name: "distance_travelled",
    type: tableau.dataTypeEnum.float,
    alias: "Distance travelled",
    description: "Distance travelled"
  }, {
    name: "idle_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Idle emission",
    description: "Idle emission"
  }, {
    name: "working_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Working emission",
    description: "Working emission"
  }, {
    name: "overall_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Overall emission",
    description: "Overall emission"
  }
];


const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `/app/reports/sync?report_type=json`;

  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo)
  let body = {
    "equipmentIds": equipments.map(a => a.id),
    "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
    "endDate": new Date().toString(),
    "reportType": "Emissions",
    "formatType": "json",
    "groupId": ""
  }
  var myHeaders = { "Content-Type": "application/json" };

  const options: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body)
  };
  let res = await fetchBase(url, options, tableau.password, geo);
  const tableData = mapObjectOntoTableauStruct(res, columns);
  return tableData;
}

export const daily_emission_report: Report = {
  id: 'EmissionsReport',
  alias: 'Daily Emissions Report',
  columns,
  get: getReport
};