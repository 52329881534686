import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';
import { backend, Geo } from "../lib/backend";

const columns: ColumnDef[] = [
    {
        name: 'dateString',
        alias: "Date",
        type: tableau.dataTypeEnum.string
    },
    {
        name: 'asset_name',
        type: tableau.dataTypeEnum.string,
        alias: "Asset"
    }, {
        name: 'distance',
        type: tableau.dataTypeEnum.float,
        alias: "Distance (KM)"
    }
];

const getReport = async () => {

    const connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    const geo = (connectionData_1.geo || "uae") as Geo;
    const account = (connectionData_1.account || "");

    const url = `https://api.tenderd.com/app/reports/sync?report_type=json`;

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo)
    let groups = await backend.getGroupsUnderAccount(
        account,
        tableau.password,
        geo
    )
    let promises = groups.map((group) => {
        let body = {
            "equipmentIds": equipments.map(a => a.id),
            "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
            "endDate": new Date().toString(),
            "reportType": "DistanceDrivenVehiclesReport",
            "formatType": "json",
            "groupId": group.id
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${tableau.password}`);
        myHeaders.append("geo", geo);
        const options: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body)
        };
        return fetch(url, options).then(resp => resp.json())
    })
    let data = await Promise.all(promises)
    let result = data.reduce((acc, val) => acc.concat(val), []);
    const tableData = mapObjectOntoTableauStruct(result, columns);
    return tableData;
}

export const distance_driven_report: Report = {
    id: 'DistanceDrivenVehiclesReport',
    alias: 'Distance Driven Vehicles Report',
    columns,
    get: getReport
};
