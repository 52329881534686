import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "mobDate",
    type: tableau.dataTypeEnum.date,
    alias: "Mobilisation date"
  },
  {
    name: "demobDate",
    type: tableau.dataTypeEnum.date,
    alias: "Demobilisation date",
    description: "Demobilisation date"
  },
  {
    name: "number_plate",
    type: tableau.dataTypeEnum.string,
    alias: "Number plate",
    description: "Number plate"
  },
  {
    name: "project",
    type: tableau.dataTypeEnum.string,
    alias: "Project",
  },
  {
    name: "equipment",
    type: tableau.dataTypeEnum.string,
    alias: "Equipment"
  },
  {
    name: "status",
    type: tableau.dataTypeEnum.string,
    alias: "Status"
  },
];

const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `/app/reports/equipment-allocation-report?report_type=json`;

  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo);
  let groups = await backend.getGroupsUnderAccount(
    account,
    tableau.password,
    geo
  )

  let promises = groups.map((group) => {

    let body = {
      "equipmentIds": equipments.map(a => a.id),
      "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
      "endDate": new Date().toISOString(),
      "reportType": "excel",
      "formatType": "json",
      "projectId": group.id,
      "accountId": account,
    }
    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body)
    };

    return fetchBase(url, options, tableau.password, geo);
  });
  let data = await Promise.all(promises);

  let result = data.reduce((acc, val) => acc.concat(val), []);
  const tableData = mapObjectOntoTableauStruct(result, columns);
  return tableData;
}

export const equipment_allocation_report: Report = {
  id: 'EquipmentAllocation',
  alias: 'Equipment allocation Report',
  columns,
  get: getReport,

};