import { backend, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "id",
    type: tableau.dataTypeEnum.string,
    alias: "ID",
    description: "ID"
  },
  {
    name: "asset_name",
    type: tableau.dataTypeEnum.string,
    alias: "Asset",
    description: "Asset"
  },
  {
    name: "device_serial_number",
    type: tableau.dataTypeEnum.string,
    alias: "Device Serial Number",
    description: "Device Serial Number"
  },
  {
    name: "company_name",
    type: tableau.dataTypeEnum.string,
    alias: "Company",
    description: "Company",
  }, 
];

const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `https://api.tenderd.com/app/reports/sync?report_type=json`;

  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo);
  let groups = await backend.getGroupsUnderAccount(
    account,
    tableau.password,
    geo
  )
  let promises = groups.map((group) => {

    let body = {
      "equipmentIds": equipments.map(a => a.id),
      "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
      "endDate": new Date().toISOString(),
      "reportType": "AssetListReport",
      "groupId": group.id,
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tableau.password}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("geo", geo);

    const options: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body)
    };

    return fetch(url, options).then(resp => resp.json())

  });
  let data = await Promise.all(promises);

  let result = data.reduce((acc, val) => acc.concat(val), []);
  const tableData = mapObjectOntoTableauStruct(result, columns);
  return tableData;
}

export const assets_list_report: Report = {
  id: 'AssetList',
  alias: 'Asset List Report',
  columns,
  get: getReport,

};