import { useEffect } from "react";
import { Report, Schema } from "./types";
import { schemaFromReport } from "./utils";
import { backend, Geo } from "./backend";
import * as allReports from "../reports";

const reports: Report[] = Object.values(allReports);

const useTableau = () => {

	useEffect(() => {
		// Create the connector object
		const myConnector = tableau.makeConnector();

		myConnector.init = function (initCallback) {

			tableau.connectionName = 'Tenderd Tableau Connector'; // This will be the data source name in Tableau
			tableau.authType = tableau.authTypeEnum.custom;

			// If we are in the auth phase we only want to show the UI needed for auth
			if (tableau.phase === tableau.phaseEnum.authPhase) {
				//$("#getvenuesbutton").css('display', 'none');
			}

			if (tableau.phase === tableau.phaseEnum.gatherDataPhase) {
				// If the API that WDC is using has an endpoint that checks
				// the validity of an access token, that could be used here.
				// Then the WDC can call tableau.abortForAuth if that access token
				// is invalid.
			}

			var connectionData = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
			var refreshToken = connectionData.refreshToken || "";
			var geo = (connectionData.geo || "uae") as Geo;
			var username = tableau.username;
			let account = connectionData.account || "";

			var hasAuth = (refreshToken && refreshToken.length > 0 && geo && geo.length > 0 && username && username.length > 0);
			//updateUIWithAuthState(hasAuth);

			initCallback();

			// If we are not in the data gathering phase, we want to store the token
			// This allows us to access the token in the data gathering phase
			if (tableau.phase === tableau.phaseEnum.interactivePhase || tableau.phase === tableau.phaseEnum.authPhase) {
				if (hasAuth) {
					backend.refreshTokens(refreshToken || "").then((result) => {

						tableau.password = result.token;
						tableau.connectionData = JSON.stringify({ refreshToken: result.refreshToken, geo: geo, account: account });
						tableau.username = username || "";

						if (tableau.phase === tableau.phaseEnum.authPhase) {
							// Auto-submit here if we are in the auth phase
							tableau.submit()
						}
					})

					return;
				}
			}
		};

		// Define the schema
		myConnector.getSchema = function (
			schemaCallback: (tableInfo: tableau.TableInfo[]) => void
		) {
			const r: Schema[] = reports.map((report) => schemaFromReport(report));

			schemaCallback(r);
		};

		// Download the data
		myConnector.getData = function (
			table: tableau.Table,
			doneCallback: tableau.DataDoneCallback
		) {

			reports.forEach(async (r) => {
				if (table.tableInfo.id === r.id) {
					r.get().then(tableData => {
						table.appendRows(tableData);
						doneCallback();
					})
				}
				return true;
			});
		};

		tableau.registerConnector(myConnector);

	}, []);

};

export default useTableau;
