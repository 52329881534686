import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "equipment_id",
    type: tableau.dataTypeEnum.string,
    alias: "Eqiupment ID",
    description: "Equipment ID",
  },
  {
    name: "no",
    type: tableau.dataTypeEnum.string,
    alias: "SN No",
    description: "Serial NO"
  },
  {
    name: "equipment",
    type: tableau.dataTypeEnum.string,
    alias: "Equipment",
    description: "Equipment Name"
  },
  {
    name: "consumption",
    type: tableau.dataTypeEnum.float,
    alias: "Fuel Consumption",
    description: "Total fuel consumed"
  },
  {
    name: "serviceHours",
    type: tableau.dataTypeEnum.float,
    alias: "Telematics Hours",
    description: "Telematics Hours"
  },
  {
    name: "totalDistance",
    type: tableau.dataTypeEnum.float,
    alias: "Total Distance (KM)",
    description: "Total distance covered"
  },
  {
    name: "hourlyConsumption",
    type: tableau.dataTypeEnum.float,
    alias: "Fuel Used/Hour",
    description: "Fuel consumed per hour"
  },
  {
    name: "fuelUsedPerKM",
    type: tableau.dataTypeEnum.float,
    alias: "Fuel Used/Kilometer",
    description: "Fuel consumed per KM"
  }
];


const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `/app/reports/sync?report_type=json`;
  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo);

  let body = {
    "equipmentIds": equipments.map(a => a.id),
    "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
    "endDate": new Date().toString(),
    "reportType": "FuelSummary",
    "formatType": "json",
    "projectId": ""
  }

  var myHeaders = { "Content-Type": "application/json" };

  const options: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body)
  };

  let res = await fetchBase(url, options, tableau.password, Geo.UAE);
  const tableData = mapObjectOntoTableauStruct(res, columns);
  return tableData;

}

export const fuel_summary_report: Report = {
  id: 'FuelSummaryReport7Days',
  alias: 'Fuel Summary Report (7 Days)',
  columns,
  get: getReport,
};