import { fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: 'Vehicle id',
        type: tableau.dataTypeEnum.string,
        alias: "Machine ID"
    },
    {
        name: 'Vehicle',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'Number plate',
        type: tableau.dataTypeEnum.string,
    }, {
        name: 'Trip name',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'Date',
        type: tableau.dataTypeEnum.date,
    },
    {
        name: 'Started at',
        type: tableau.dataTypeEnum.datetime,
    },
    {
        name: 'Ended at',
        type: tableau.dataTypeEnum.datetime,
    }, {
        name: 'Geofences',
        type: tableau.dataTypeEnum.string,
    }, {
        name: 'Duration (hours)',
        type: tableau.dataTypeEnum.float,
    }, {
        name: 'Distance (km)',
        type: tableau.dataTypeEnum.float,
    }, {
        name: 'Fuel consumed (liters)',
        type: tableau.dataTypeEnum.float,
        alias: "Fuel Consumed (Trips)"
    }, {
        name: 'Route deviation violations',
        type: tableau.dataTypeEnum.float,
    }, {
        name: 'Idling violations',
        type: tableau.dataTypeEnum.float,
    }, {
        name: 'Overspeed violations',
        type: tableau.dataTypeEnum.float,
    },
];

const getReport = async () => {
    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var account = (connectionData_1.account || "");
    var geo = (connectionData_1.geo || "uae") as Geo;

    let url = `/logistics/v1/reports/trips?report_type=json`

    let body = {
        "accountId": account,
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
        "endDate": new Date().toISOString(),
        "geo": geo
    }

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };
    let res = await fetchBase(url, options, tableau.password, geo);
    res = res.map((a: any) => {
        return {
            ...a,
            "Date": new Date(a["Started at"]).toISOString().split("T")[0],
        }
    })
    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const completed_trips_report: Report = {
    id: 'completedTripsReport30Days',
    alias: 'Completed Trips Report (30 Days)',
    columns,
    get: getReport,
};