import { backend, fetchBase, Geo } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';

const columns: ColumnDef[] = [
  {
    name: "machine_id",
    type: tableau.dataTypeEnum.string,
    alias: "Machine ID"
  },
  {
    name: "machine_model",
    type: tableau.dataTypeEnum.string,
    alias: "Machine model",
    description: "Machine model as specified by the manufacturer"
  },
  {
    name: "machine_plate_number",
    type: tableau.dataTypeEnum.string,
    alias: "Number plate",
    description: "Number plate in the regional format"
  },
  {
    name: "date",
    type: tableau.dataTypeEnum.date,
    alias: "Date (DD-MM-YYYY)",
  },
  {
    name: "fuel_consumed",
    type: tableau.dataTypeEnum.float,
    alias: "Fuel Consumed (Emissions)"
  },
  {
    name: "engine_on",
    type: tableau.dataTypeEnum.float,
    alias: "Engine On (hours)"
  },
  {
    name: "distance_travelled",
    type: tableau.dataTypeEnum.float,
    alias: "Distance Travelled (km)"
  },
  {
    name: "idle_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Idle Emission (kg)"
  },
  {
    name: "working_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Working Emission (kg)"
  },
  {
    name: "overall_emission",
    type: tableau.dataTypeEnum.float,
    alias: "Overall Emission (kg)"
  }
];

const getReport = async () => {

  var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
  var geo = (connectionData_1.geo || "uae") as Geo;
  var account = (connectionData_1.account || "");

  const url = `/app/reports/sync?report_type=json`;

  let equipments = await backend.getEquipmentsByAccount({
    accountId: account,
    limit: 500,
  }, tableau.password, geo);

  let body = {
    "equipmentIds": equipments.map(a => a.id),
    "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
    "endDate": new Date().toString(),
    "reportType": "Emissions",
    "formatType": "json",
    "projectId": "",
    "summary": false
  }
  var myHeaders = { "Content-Type": "application/json" };

  const options: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body)
  };

  let res = await fetchBase(url, options, tableau.password, geo);
  const tableData = mapObjectOntoTableauStruct(res, columns);
  return tableData;
}

export const emission_report: Report = {
  id: 'emissionReport7Days',
  alias: 'Emission Report (7 Days)',
  columns,
  get: getReport,

};