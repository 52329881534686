import {backend, fetchBase, Geo} from '../lib/backend';
import {ColumnDef, Report} from '../lib/types';
import {mapObjectOntoTableauStruct} from '../lib/utils';

const columns: ColumnDef[] = [
    {
        name: "device",
        type: tableau.dataTypeEnum.string,
        alias: "Device",
        description: "Equipment model and number plate"
    },
    {
        name: "date",
        type: tableau.dataTypeEnum.datetime,
        alias: "Date",
        description: "Violation Date"
    },
    {
        name: "violation_type",
        type: tableau.dataTypeEnum.string,
        alias: "Violation Type",
        description: "Violation Type"
    },
    {
        name: "speed",
        type: tableau.dataTypeEnum.string,
        alias: "Speed",
        description: "Violation Speed"
    },
    {
        name: "top_speed",
        type: tableau.dataTypeEnum.string,
        alias: "Speed",
        description: "Top Speed"
    },
    {
        name: "overspeed_duration",
        type: tableau.dataTypeEnum.string,
        alias: "Overspeed",
        description: "Overspeed Duration"
    },
    {
        name: "overspeed_duration_human_readable",
        type: tableau.dataTypeEnum.string,
        alias: "Overspeed Duration",
        description: "Overspeed Duration Human Readable"
    },
    {
        name: "threshold",
        type: tableau.dataTypeEnum.float,
        alias: "Threshold",
        description: "Threshold"
    },
    {
        name: "location",
        type: tableau.dataTypeEnum.string,
        alias: "Location",
        description: "Location"
    }
];

const getReport = async () => {

    const connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    const geo = (connectionData_1.geo || "uae") as Geo;
    const account = (connectionData_1.account || "");

    const url = `/app/reports/sync?report_type=json`;

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);

    let body = {
        "equipmentIds": equipments.map(a => a.id),
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toString(),
        "endDate": new Date().toString(),
        "reportType": "Violation",
        "formatType": "json",
        "projectId": "",
        "summary": false
    }
    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };

    let res = await fetchBase(url, options, tableau.password, geo);
    return mapObjectOntoTableauStruct(res, columns);
}

export const violation_report: Report = {
    id: 'violationReport7Days',
    alias: 'Violation Report (7 Days)',
    columns,
    get: getReport,

};
