import { Geo, backend, fetchBase } from '../lib/backend';
import { ColumnDef, Report } from '../lib/types';
import { mapObjectOntoTableauStruct } from '../lib/utils';


const columns: ColumnDef[] = [
    {
        name: 'S/N',
        type: tableau.dataTypeEnum.int,
    },
    {
        name: 'CUSTOMER',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'TRUCK',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'AVERAGE OF OUTBOUND JOURNEY (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'AVERAGE OF INBOUND JOURNEY (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'AVERAGE OF WAITING (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'AVERAGE OF TOTAL DISTANCE (KM)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'AVERAGE OF OVERALL TURNAROUND (TIME)',
        type: tableau.dataTypeEnum.string,
    },
    {
        name: 'COUNT OF OUTBOUND DEPARTURE',
        type: tableau.dataTypeEnum.int,
    },
]

const getReport = async () => {
    var connectionData_1 = tableau.connectionData ? JSON.parse(tableau.connectionData) : {};
    var account = (connectionData_1.account || "");
    var geo = (connectionData_1.geo || "uae") as Geo;
    let url = `/logistics/v1/report/truck-efficiency?report_type=json`

    let equipments = await backend.getEquipmentsByAccount({
        accountId: account,
        limit: 500,
    }, tableau.password, geo);
    let body = {
        "accountId": account,
        "startDate": new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toISOString(),
        "endDate": new Date().toISOString(),
        "equipments": equipments.map(a => a.id),
    }

    var myHeaders = { "Content-Type": "application/json" };

    const options: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body)
    };
    let res = await fetchBase(url, options, tableau.password, geo);
    const tableData = mapObjectOntoTableauStruct(res, columns);
    return tableData;

}

export const truck_efficiency_report: Report = {
    id: 'truckEfficiencyReport7Days',
    alias: 'Truck Efficiency Report',
    columns,
    get: getReport,
};